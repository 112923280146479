<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <q-b-header></q-b-header>
        <v-snackbar
            top
            v-model="snackbar"
        >
          {{ msg }}
          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text

                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1"
            >
              <template v-slot:item.date="{item}">
                <span>{{ item.created_at.substr(0, 10) }}</span>
              </template>
              <template v-slot:item.delete="{item}">
                <v-btn small color="red" @click="deleteComment(item.id)">Delete</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else>
      <div>
        <q-b-header-mobile></q-b-header-mobile>
        <v-snackbar
            top
            v-model="snackbar"
        >
          {{ msg }}
          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text

                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1"
            >
              <template v-slot:item.date="{item}">
                <span>{{ item.created_at.substr(0, 10) }}</span>
              </template>
              <template v-slot:item.delete="{item}">
                <v-btn small color="red" @click="deleteComment(item.id)">Delete</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>

</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  components: {
    QBHeaderMobile,
    QBHeader,
  },
  data() {
    return {
      screenType: null,
      noComments: false,
      msg: '',
      snackbar: false,
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {text: 'Comment-ID', value: 'id'},
        {text: 'Title', value: 'title'},
        {text: 'Content', value: 'body'},
        {text: 'question-ID', value: 'question_id'},
        {text: 'Delete', value: 'delete'},
      ],
      items: []
    }
  },
  mounted() {
    this.getScreenType()
    this.getComments()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    getComments() {
      window.axios.get('api/exam/comment').then((res) => {
        this.items = res.data.data
      }).catch(() => {
        this.noComments = true
      })
    },
    deleteComment(id) {
      window.axios.get('api/exam/comment/delete/' + id).then(() => {
        this.snackbar = true
        this.msg = 'Your note deleted successfully'
        this.getComments()
      }).catch((err) => {
        this.snackbar = true
        this.msg = err.messages
        this.getComments()
      })
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.big {
  font-size: 19px;
}

.icon-page-hover:hover {
  color: #39bf88
}

.icon-view-hover:hover {
  color: #f1803f !important;
}
</style>